<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Un étudiant produit une CCM dans le but de suivre de progrès d’une réaction.
        Malheureusement, les taches sont trop grandes et il est impossible de distinguer les
        différents composés. Parmi toutes les options suivantes, sélectionnez le ou les
        changement(s) qui peuvent être apporté pour rectifier cette erreur lors de la prochaine
        analyse CCM de cet étudiant.
      </p>

      <div v-for="option in options" :key="option.value" class="mb-0 pl-6">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block my-0 py-1"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'uOttawa1321Prelab1Q7Fr',
  components: {
    DragToRankInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {text: 'Changer le solvant dans lequel le composé est dissout', value: 'changeSolvent'},
        {text: 'Utiliser un plus petit capillaire', value: 'smallerCapillary'},
        {text: 'Appliquer le composé sur la plaque CCM plus rapidement', value: 'applyFaster'},
        {text: 'Dessiner la ligne de base plus bas sur la CCM', value: 'lowerBaseline'},
        {text: 'Toutes ces réponses', value: 'all'},
      ],
    };
  },
};
</script>
